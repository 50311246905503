export const clientNameFooter = "Escola Lótus";
export const titleMain = "";
export const subtitleMain = "";

export const aspectRatioLogoAbout = '1'; // width/height
export const aspectRatioLogoMain = '1'; // width/height

export const borderRadiusButtons = '30px';

export const nomeFornecedorCompleto = "LÓTUS DESENVOLVIMENTO PESSOAL LTDA";
export const nomeFornecedor = "ESCOLA LÓTUS";
export const artigo = "a";
export const artigoCaps = "A";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://www.instagram.com/lotus.escoladeyoga/",
    profile: "@lotus.escoladeyoga"
  },
  {
    type: "material-community",
    name: "youtube",
    link: "https://www.youtube.com/channel/UCUExP6wuVHjVRwecp-WfV_Q",
  },

  {
    type: "material-community",
    name: "whatsapp",
    link: "https://api.whatsapp.com/send?phone=555197218500",
    number: "(51) 99721-8500"
  },
];

export const drawerClosedLinks = [
  {
    title: "Fale Comigo",
    link: "https://api.whatsapp.com/send?phone=555197218500",
  },
];

export const linkAppleStore = "https://apps.apple.com/br/app/l%C3%B3tus/id1641556080"
export const linkPlayStore = "https://play.google.com/store/apps/details?id=app.web.mobile_escola_lotus.twa"

export const hasOutsideAboutPage = true;
export const drawerOpenedLinks = [{
  title: "Compre aqui",
  link: "https://lotusescoladeyoga.com.br/",
}];
